@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
}

.title {
	padding: 1.6rem;
	border-bottom: 1px solid $gray-2;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			background-color: $gray-1;
		}

		&:active {
			background-color: $gray-2;
		}
	}
}

.options {
	padding: 0 0.4rem;
	padding-bottom: 1.6rem;
	cursor: pointer;

	.list {
		margin: 0.8rem 0 0;
	}

	@media screen and (min-width: $media-md) {
		padding: 0;
	}
}

.button {
	padding: 1rem;
}

.swatches {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 1.6rem;
	justify-items: center;
}